import React from 'react';
import clsx from 'clsx';
import { ITab } from '../app/types/customizer';
import { getSelectedInfo } from './commonRingUtils';

export const REQUIRED_TAB_FIELDS = ['ringSize', 'engraving'];
export const TABS_TO_AVOID = ['summary', 'data', 'weight', 'none', 'placement'];

export const getRowContent = (tab: ITab, index: number, options: { [key: string]: boolean } = {}) => {
    const selectedOption = getSelectedInfo(tab);

    let title = typeof tab.title === 'string' ? tab.title : typeof tab.title === 'function' && tab.title();
    const selectedText = selectedOption?.text || '';

    if (tab.eventKey === 'shape') {
        const filteredTabs = tab.items?.filter(item => !TABS_TO_AVOID.includes(item.eventKey)) || [];
        return (
            <>
                {filteredTabs.map((item) => (
                    <div className='tw-mt-1'>
                        <p>{item.label || ''}</p>
                        <p>{(item.content?.selectedOption?.short_name || item.content?.selectedOption?.name )}</p>
                    </div>
                ))}
            </>
        )
    }

    if (tab.eventKey === 'finish' && selectedText.includes(' - ')) {
        const [firstFinish, secondFinish] = selectedText.split(' - ');
        return (
            <>
                <div className="tw-mt-1">
                    <p>First Finish</p>
                    <p>{firstFinish}</p>
                </div>
                <div className="tw-mt-1">
                    <p>Second Finish</p>
                    <p>{secondFinish}</p>
                </div>
            </>
        );
    }

    if (['outsideFeature_1', 'outsideFeature_2'].includes(tab.eventKey)) {
        if(tab.eventKey === 'outsideFeature_2') {
            title = 'Secondary Outside Feature';
        }

        const filteredTabs = tab.items?.filter(item => !TABS_TO_AVOID.includes(item.eventKey)) || [];
        return (
            <>
                <div className="tw-mt-3">
                    <p>{title}</p>
                    <p>{tab.selectedFeature || 'None'}</p>
                </div>
                {filteredTabs.map((item) => (
                    <div>
                        <p>{item.label || ''}</p>
                        <p>{item.content?.selectedOption?.is_reverse_selected? 'Rev ': ''}{(item.content?.selectedOption?.name || 'None').replace(/(\d+)mm/g, '$1 mm')}</p>
                    </div>
                ))}
            </>
        );
    }

    if (tab.eventKey === 'insideFeature') {
        return (
            <div className="tw-mt-3">
                <p>{title}</p>
                <p>{selectedText}</p>
            </div>
        );
    }

    if(tab.eventKey === 'logo') {
        const [school, logo, accentColor] = selectedText.split(' - ');
        return (
            <>
                <div className="tw-mt-1">
                    <p>School</p>
                    <p>{school}</p>
                </div>
                <div className="tw-mt-1">
                    <p>Logo</p>
                    <p>{logo}</p>
                </div>
                <div className="tw-mt-1">
                    <p>Accent Color</p>
                    <p>{accentColor}</p>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="tw-mt-1">
                <p>{title}</p>
                <p>{selectedText}</p>
            </div>

            {tab.eventKey === 'engraving' && selectedOption?.engravingText && (
                <div className="tw-mt-1">
                    <p>Engraving Text</p>
                    <p>{selectedOption.engravingText}</p>
                </div>
            )}

        </>
    );
};