import SwatchFilter from '../app/components/SwatchFilter';
import { ITabs, ITab } from '../app/types/customizer';
import { getFilterMetaData, getSelectedOption, getWarning } from './commonRingUtils';
export const getV3InlayItems = (product: any) => {
  const items: ITabs = [
    {
        title: 'Inlay',
        label: 'Inlay',
        eventKey: 'inlays',
        component: SwatchFilter,
        isEnabled: product?.inlays?.inlay?.length > 0,
        content: {
            hasSwatch: true,
            fieldType: 'signet_inlay',
            options: product?.inlays?.inlay || [],
            selectedOption: getSelectedOption(product?.inlays?.inlay || []),
        },
    },
    {
        title: 'Inlay Finish',
        label: 'Finish',
        eventKey: 'inlays_finish',
        component: SwatchFilter,
        isEnabled: product?.inlays?.inlayFinish?.length > 0,
        content: {
            hasSwatch: true,
            fieldType: 'signet_inlayfinish',
            options: product?.inlays?.inlayFinish,
            selectedOption: getSelectedOption(product?.inlays?.inlayFinish),
        },
    },
  ];
  const filtered = items.filter((tab: ITab) => tab.isEnabled || tab.items?.length);
  return filtered;
}