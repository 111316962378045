import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Mousewheel } from "swiper";
import clsx from "clsx";
import { ICategoryOption, IOption, ISwatchContent } from "../../types/customizer";
import SwatchOption from "../SwatchOption";
import "../../../../node_modules/swiper/swiper.min.css";

import './sizeFilter.scss';
import { useResizeDetector } from "react-resize-detector";

export interface ISizeFilterProps {
  content: ISwatchContent;
  onChange: (updated: any) => void;
  innerTab?: number;
  isFetching: boolean;
}

const SizeFilter = ({ content, onChange, isFetching, innerTab = 1 }:ISizeFilterProps) => {

  const { fieldType } = content;
  const options: Array<IOption> = content.options as Array<IOption>;

  const { width, ref } = useResizeDetector();
  let timeoutId: any = null;
  const [isChanging, setIsChanging] = useState('');
  const [swiper, setSwiper] = useState<any>(null);
  const [activeIndex, setActiveIndex] = useState(() => {
    const index = options.findIndex(item => item.selected);
    return index;
  });

  useEffect(() => {
    if (!isFetching) {
      setIsChanging('');
    }
  }, [isFetching]);

  useEffect(() => {
    const index = options.findIndex(item => item.selected);
    setActiveIndex(index);
  }, [options]);

  // useEffect(() => {
  //   swiper?.slideToLoop(activeIndex);
  // }, [activeIndex, swiper, options.length]);

  useEffect(() => {
    if (width) {
      ref.current.style.width = `${width + 1}px`;
      setTimeout(() => {
        ref.current.style.width = `${width}px`;
      }, 100);
    }
  }, [!!width]);

  const handleChange = (updatedObj: Record<string, string>) => {
    setIsChanging(Object.values(updatedObj)[0]); // used for deseclecting old one till response recieved
    onChange(updatedObj);
  };

  // const handleSlideChange = (e: any) => {
  // /**
  //  * On Css mode while slider, onSlideChange trigger everytime till animation ends, So call timeout after 500ms
  //  */
  //   clearTimeout(timeoutId);
  //   timeoutId = setTimeout(() => {
  //     const index = e.realIndex;
  //     if (index !== null && index !== undefined && index !== activeIndex) {
  //       setActiveIndex(index);
  //       const option = options[index];
  //       handleChange({ [fieldType]: option.sku });
  //     }
  //   }, 500);
  // };

  const handleSizeChange = (index: number) => () => {
    if (index !== null && index !== undefined && index !== activeIndex) {
      setActiveIndex(index);
      const option = options[index];
      handleChange({ [fieldType]: option.sku });
    }
  }

  return (
    <div className="tw-flex tw-justify-center tw-pb-2 swatch-filter-border" style={{ height: `calc(100% - ${45 * innerTab}px)` }} ref={ref}>
      <div className="tw-w-full sm:tw-w-full tw-px-4">
        <div className="tw-mb-2 tw-mt-5 xs:tw-text-center sm:tw-text-left">
          <div className="tw-inline-block xs:tw-w-4/6 sm:tw-w-4/6 tw-text-base">Select your finger size</div>
        </div>
        <div className="tw-overflow-y-auto tw-overflow-x-hidden tw-pb-2" ref={ref} style={{ height: 'calc(100% - 100px)' }}>
          {/*
            1. Removed slideToClickedSlide as it interferes with natural scrolling
            2. Removed grabCursor as it's not needed for scroll behavior
            3. Removed resistance to allow natural scrolling to edges
            4. Enhanced freeMode configuration with momentum settings
          */}
          <Swiper
            onSwiper={setSwiper}
            slidesPerView="auto"
            initialSlide={activeIndex}
            centeredSlides={true}
            slideToClickedSlide={false}
            grabCursor={false}
            resistance={false}
            loop={false}
            cssMode={false}
            spaceBetween={8}
            freeMode={{
              enabled: true,
              momentum: true,
              momentumRatio: 1,
              momentumVelocityRatio: 1
            }}
            // onSlideChange={handleSlideChange}
            mousewheel={{
              forceToAxis: true,
              sensitivity: 1
            }}
            modules={[Mousewheel, FreeMode]}
          >
            {options.map((option: IOption | ICategoryOption, index: number) =>
              <SwiperSlide key={index} className={clsx('size-slide tw-cursor-pointer', { 'active-slide': index === activeIndex })} onClick={handleSizeChange(index)}>
                {option.name}
              </SwiperSlide>)
            }
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default SizeFilter;
