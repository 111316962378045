import SwatchFilter from '../app/components/SwatchFilter';
import { ITab, ITabs } from '../app/types/customizer';
import { getSelectedOption } from './commonRingUtils';

export const getLogoItems = (product: any) => {
  const items: ITabs = [
     {
        title: 'School',
        eventKey: 'school',
        component: SwatchFilter,
        isEnabled: product.school?.length > 0,
        content: {
            hasSwatch: true,
            fieldType: 'school',
            options: product.school,
            selectedOption: getSelectedOption(product.school),
        },
      },
      {
          title: 'Logo',
          label: 'Signet Engraving',
          eventKey: 'signet_engraving',
          component: SwatchFilter,
          isEnabled: product.signet_engraving?.length > 0,
          content: {
              hasSwatch: true,
              fieldType: 'signet_engraving',
              options: product.signet_engraving,
              selectedOption: getSelectedOption(product.signet_engraving),
          },
      },
      {
          title: 'Color',
          label: 'Cerakote Color',
          eventKey: 'cerakote_color',
          component: SwatchFilter,
          isEnabled: product.cerakote_color?.length > 0,
          content: {
              hasSwatch: true,
              fieldType: 'cerakote_color',
              options: product.cerakote_color,
              selectedOption: getSelectedOption(product.cerakote_color),
          },
      }
  ];
  const filtered = items.filter((tab: ITab) => tab.isEnabled || tab.items?.length);
  return filtered;
};