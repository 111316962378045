import { ReactNode } from "react";
import SwatchFilter from "../app/components/SwatchFilter";
import { ISwatchContent, ITab } from "../app/types/customizer";
import { getFilterMetaData, getFontOptions, getSelectedOption, getWarning } from "./commonRingUtils";
import crossWhite from '../assets/images/cross-white.svg';
import crossBlack from '../assets/images/cross-black.svg';
import EngravingFilter from "../app/components/EngravingFilter/EngravingFilter";
import EngravingNoneTabFilter from "../app/components/EngravingNoneTabFilter";

export const getEngravingItems = (product: any, engraving: any) => {
    if (!engraving) {
        return {};
    }
    const engravingOptions = engraving.types.options || [];
    const SKU_LIST: string[] = engravingOptions.map((item: any) => item.sku);
    const selectedSKU = engravingOptions.find((item: any) => item.selected);
    const obj: {isEnabled?: boolean; content?: ISwatchContent, items?: ITab[], selectedItem: string, component?: React.ComponentType<any>;} = {
        component: undefined,
        content: undefined,
        items: undefined,
        selectedItem: '',
    };
    if (SKU_LIST.includes('NONE')) {
        obj.selectedItem = 'none';
        obj.component = EngravingNoneTabFilter;
        obj.isEnabled = true;
        obj.content = {
            hasSwatch: true,
            fieldType: 'engraving_new',
            options: engravingOptions,
            selectedOption: selectedSKU,
        };
    }
    if (SKU_LIST.includes('D') && selectedSKU.sku === 'D') {
        obj.selectedItem = 'pattern';
        obj.items = [
            {
                title: (isActive) => isActive ? <i className="fa fa-times-circle tw-text-gray-400 tw-text-xl"></i> : <i className="fa fa-times-circle tw-text-black tw-text-xl"></i>,
                eventKey: 'engraving_none',
                isSelected: selectedSKU?.sku === 'NONE',
                actionPayload: { engraving_none: 'NONE' },
                isEnabled: true,
                component: () => null
            },
            {
                title: 'Pattern',
                label: 'Engraving Pattern',
                eventKey: 'engraving_pattern',
                isSelected: selectedSKU?.sku === 'D',
                actionPayload: { engraving_new: 'D' },
                component: SwatchFilter,
                isEnabled: engraving?.design_patterns?.options?.length > 0,
                content: {
                    hasSwatch: true,
                    fieldType: 'engraving_pattern',
                    options: getFontOptions(engraving?.design_patterns?.options || []),
                    selectedOption: getSelectedOption(engraving?.design_patterns?.options),
                },
            },
        ];
    }

    if (SKU_LIST.includes('T') && selectedSKU.sku === 'T') {
        obj.selectedItem = 'text';
        obj.items = [
            {
                title: (isActive) => isActive ? <i className="fa fa-times-circle tw-text-gray-400 tw-text-xl"></i> : <i className="fa fa-times-circle tw-text-black tw-text-xl"></i>,
                eventKey: 'engraving_none',
                isSelected: selectedSKU?.sku === 'NONE',
                actionPayload: { engraving_none: 'NONE' },
                isEnabled: true,
                component: () => null
            },
            {
                title: 'Text',
                label: 'Engraving Font',
                eventKey: 'engraving',
                isSelected: selectedSKU?.sku === 'T',
                component: EngravingFilter,
                actionPayload: { engraving_new: 'T' },
                isEnabled: engraving?.text?.fonts?.length > 0,
                content: {
                    hasSwatch: false,
                    fieldType: 'engraving_new',
                    options: getFontOptions(engraving?.text?.fonts || []),
                    selectedOption: getSelectedOption(engraving?.text?.fonts),
                    engravingText: engraving?.text?.text,
                },
            },
        ];
    }
    return obj;
};
