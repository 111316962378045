import React from 'react';
import { getSelectedOption } from './commonRingUtils';
import { getV3FinishItems } from './finishItems';
import { getOutsideFeatureItems } from './outsideFeatureItems';
import { getCategorizedOptions, getTypeOptions } from './basic';
import { ITab, ITabs } from '../app/types/customizer';
import BoxFilter from '../app/components/BoxFilter';
import SwatchFilter from '../app/components/SwatchFilter';
import SizeFilter from '../app/components/SizeFilter/SizeFilter';
import Summary from '../app/components/Summary';
import { getV3ShapeItems } from './shapeItems';
import { getLogoItems } from './logoItems';
import { IPageData } from '../app/pages/RingCustomizer/Pages';
import SummaryInfo from '../app/components/SummaryInfo/SummaryInfo';
import { getSecondaryOutsideFeatureItems } from './secondaryOutsideFeatureItems';
import { getV3InlayItems } from './InlayItems';

export const getV3PageData = (props: IPageData) => {
    const { product: productData, ring_type, subpath, storeData, isBuildFromScratch } = props;
    const product = { ...productData };
    product.categories = getCategorizedOptions(ring_type, storeData);
    product.types = getTypeOptions(ring_type);

    const items: ITabs = [
        {
            title: 'Category',
            eventKey: 'category',
            component: BoxFilter,
            isEnabled: true,
            isSelected: isBuildFromScratch,
            content: {
                hasSwatch: true,
                fieldType: 'category',
                options: product.categories,
                selectedOption: getSelectedOption(product.categories),
            },
        },
        // {
        //     title: 'Type',
        //     eventKey: 'type',
        //     component: BoxFilter,
        //     isEnabled: product.types.length > 0,
        //     content: {
        //         hasSwatch: true,
        //         fieldType: 'types',
        //         options: product.types,
        //         selectedOption: getSelectedOption(product.types),
        //     },
        // },
        {
            title: 'Logo',
            eventKey: 'logo',
            items: getLogoItems(product),
            tabStyle: 'box',
            isSelected: !isBuildFromScratch,
        },
        {
            title: 'Shape',
            eventKey: 'shape',
            items: getV3ShapeItems(product),
            tabStyle: 'box',
            isSelected: !isBuildFromScratch,
        },
        {
            title: 'Material',
            eventKey: 'material',
            component: SwatchFilter,
            isEnabled: product.ring_material?.length > 0,
            content: {
                hasSwatch: true,
                fieldType: 'ring_materials',
                options: product.ring_material,
                selectedOption: getSelectedOption(product.ring_material),
            },
        },
        {
            title: 'Finish',
            eventKey: 'finish',
            items: getV3FinishItems(product),
            tabStyle: 'box',
        },
        {
            title: 'Inlay',
            eventKey: 'signet_inlays',
            items: getV3InlayItems(product),
            tabStyle: 'box',
            isSelected: !isBuildFromScratch,
        },
        {
            title: 'Outside Feature',
            eventKey: 'outsideFeature_1',
            ...getOutsideFeatureItems(product),
            tabStyle: 'box',
        },
        {
            title: () => <>2<sup>nd</sup> Outside Feature</>,
            eventKey: 'outsideFeature_2',
            ...getSecondaryOutsideFeatureItems(product),
            tabStyle: 'box',
        },
        {
            title: 'Size',
            eventKey: 'ringSize',
            component: SizeFilter,
            isEnabled: product.finger_size?.length > 0,
            content: {
                hasSwatch: false,
                fieldType: 'finger_size',
                options: product.finger_size || [],
                selectedOption: getSelectedOption(product.finger_size),
            },
        },
        {
            title: 'Summary',
            eventKey: 'summary',
            component: Summary,
            isEnabled: subpath !== 'customize',
        },
        {
            title: 'Info',
            eventKey: 'summaryInfo',
            component: SummaryInfo,
            isEnabled: subpath === 'customize',
        },
    ];

    const filtered = items.filter((tab: ITab) => tab.isEnabled || tab.items?.length);
    return filtered;
};
