/* eslint-disable react-hooks/exhaustive-deps */
import { useState, CSSProperties } from "react";
import { useEffect } from "react";
import clsx from "clsx";
import { ITabs} from "../../types/customizer";
import './index.scss';
import leftArrow from "../../../assets/images/left-chevrolet.svg";
import lashbrookLogo from '../../../assets/images/LB_Logoblue.png'
import {useRingCustomizerContext} from "../../context/RingCustomizerContext";
import { twMerge } from "../../types/twMerge";
import { FONT_MAP, FONT_SIZE_MAP, getSelectedInfo } from "../../../utils/commonRingUtils";
import If from "../If";
import { APP_STYLES, DEFAULT_STYLES } from "../../../utils/basic";
import { getLastestShippingDate } from "../Summary";

interface Props {
	pages: ITabs;
	selectedTab: string;
	handleActiveTab: Function;
	price: string;
  shippingOptions: any;
}

interface SelectedOption {
	url: string;
	text: string;
	engravingText?: string;
}

const DesktopSidebar = ({
  pages,
  selectedTab,
  handleActiveTab,
  price,
	shippingOptions
}: Props) => {
	const [horizontalStyle, setHorizontalStyle] = useState<CSSProperties>({});
	const [verticalStyle, setVerticalStyle] = useState<CSSProperties>({});

	useEffect(() => {
		setHorizontalStyle(getHorizontalStyle());
		setVerticalStyle(getVerticalStyle());
	}, [selectedTab, pages])
	const {
		subpath,
		product,
		handleAddToCart,
		handleDone,
	} = useRingCustomizerContext();


	const getHorizontalStyle = ():CSSProperties => {
		const tabContentRect = document.getElementById('main-content')?.getBoundingClientRect();
		const sidebarRect = document.getElementById(selectedTab)?.getBoundingClientRect();
		if(tabContentRect && sidebarRect) {
			let tabcontentTop = tabContentRect.top;
			const sidebarTopX = sidebarRect.top ;
			const sidebarBottomX = sidebarRect.top  + sidebarRect.height;

			const selectedPage = pages.find(item => item.eventKey === selectedTab);
			const hasItems =  selectedPage?.items?.length || 0;
			tabcontentTop = tabcontentTop + (hasItems ? 47 : 2);

			if (tabcontentTop - sidebarTopX > 20 && tabcontentTop - sidebarBottomX > 20) { // If side tab above 20px of margin line
				return {
					bottom: '0px',
					top: 'unset',
				}
			} else if(sidebarTopX - tabcontentTop > 20 && sidebarBottomX  - tabcontentTop > 20) { // If side tab below 20px of margin line
				return {
					top: '0px',
					bottom: 'unset'
				}
			} else if(tabcontentTop - sidebarTopX > 20 ) { // If sidebar top above 20px of margin line
				return {
					top: '0px',
					bottom: 'unset'
				}
			} else  {
				return {
					bottom: '0px',
					top: 'unset',
				}
			}
		}
		return {}
	}

	const getVerticalStyle = ():CSSProperties => {
		const tabContentRect = document.getElementById('main-content')?.getBoundingClientRect();
		const sidebarRect = document.getElementById(selectedTab)?.getBoundingClientRect();
		if(tabContentRect && sidebarRect) {
			let tabcontentTop = tabContentRect.top;
			const sidebarTopX = sidebarRect.top ;
			const sidebarBottomX = sidebarRect.top  + sidebarRect.height;

			const selectedPage = pages.find(item => item.eventKey === selectedTab);
			const hasItems =  selectedPage?.items?.length || 0;
			tabcontentTop = tabcontentTop + (hasItems ? 47 : 2);

			let height = 0;
			if (tabcontentTop - sidebarTopX > 20 && tabcontentTop - sidebarBottomX > 20) { // If side tab above 20px of margin line
				height = tabcontentTop - sidebarBottomX;
				return {
					height: height + 'px',
					top: '100%',
					bottom: 'unset'
				}
			} else if(sidebarTopX - tabcontentTop > 20 && sidebarBottomX  - tabcontentTop > 20) { // If side tab below 20px of margin line
				height = sidebarTopX - tabcontentTop;
				return {
					height: height + 'px',
					bottom: '100%',
					top: 'unset'
				}
			} else if(tabcontentTop - sidebarTopX > 20) { // If sidebar top above 20px of margin line
				height = tabcontentTop - sidebarTopX;
				return {
					height: height + 'px',
					top: '0px',
					bottom: 'unset',
				}
			} else {
				height = sidebarBottomX - tabcontentTop;
				return {
					height: height + 'px',
					top: 'unset',
					bottom: '0px',
				}
			}
		}
		return {}
	}

	const getUrl = (swatch_url: string) => {
		return swatch_url.indexOf('http') !== -1 ? swatch_url : `${process.env.REACT_APP_ASSET_URL}${swatch_url}`
}

	const styles = APP_STYLES[subpath] || DEFAULT_STYLES;
	return (
		<div className={clsx('desktop-sidebar xs:tw-hidden sm:tw-flex tw-flex-col tw-bg-primary-bg-color tw-h-full tw-overflow-y-auto tw-overflow-x-hidden tw-py-2 sm:tw-w-[260px] lg:tw-w-[300px] xl:tw-w-[320px]', {
			'left': styles.sidebarOrder === 0,
			'right': styles.sidebarOrder === 1,
		})}>
			<div className="tw-flex tw-flex-col tw-items-center">
				<div className={clsx("tw-w-3/4 tw-text-center tw-mt-2 tw-inline-block", { 'tw-hidden': styles.showTopBar })} onClick={handleLogoClick}>
					<img className="brand-logo" alt="brand_logo"/>
				</div>
				<div className={clsx("tw-text-right tw-text-lg primary-font-family tw-text-primary-color tw-w-5/6 tw-mt-2", { 'tw-hidden': !styles.logoSubText })}>
					RING BUILDER
				</div>
			</div>
			<div className="tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-w-full secondary-font-family">
				<If condition={styles.enablePoweredBy}>
					<p className="tw-mt-5 tw-text-center">
						<span className="tw-text-base">Powered by</span>
						<img className="tw-inline-block tw-h-6 tw-ml-2" src={lashbrookLogo} alt="Lashbrook Builder"/>
					</p>
				</If>
				<div className="tw-flex-1 tw-flex tw-flex-col tw-w-full tw-my-6">
					{pages.map(item => {
						const selectedInfo = getSelectedInfo(item);
						const selectedText = selectedInfo?.text || '';
						return (
							<div
								id={item.eventKey}
								key={item.eventKey}
								className={clsx(`tw-flex tw-flex-col tw-justify-center tw-cursor-pointer tw-pl-3 tw-py-2 tw-border-gray-500 sidebar-tab`,
								{'tw-bg-white tw-pr-4 active': selectedTab === item.eventKey},
								{'tw-border-t tw-mr-4': selectedTab !== item.eventKey})}
							  onClick={handleActiveTab?.(item.eventKey, false)}>
								<div className='tw-flex tw-items-center tw-justify-between'>
									<div className="tw-flex tw-items-start tw-justify-center tw-flex-col tw-tab-text">
										<p
											className={'tw-relative primary-font-family tw-text-base tw-font-medium tw-text-primary-color'}>
											{typeof item.title === 'string' && item.title}
											{typeof item.title === 'function' && item.title()}
										</p>
										{item?.disclaimer && <p className="tw-text-xs tw-mt-2">{item?.disclaimer}</p>}
										{/* For outside and 2nd outside alone */}
										{item.selectedFeature && <p className="tw-text-xs tw-mt-2">{item.selectedFeature}</p>}
										{/* For engraving alone */}
										<If condition={item.eventKey === 'engraving' && item.selectedItem === 'text'}>
											<p className={twMerge("tw-text-xs tw-mt-2", FONT_MAP[selectedText || ''], !['Arial', 'Lucida Calligraphy', 'Times New Roman'].includes(selectedText) ? FONT_SIZE_MAP[selectedText || ''] : '' , 'tw-tracking-normal')}>
												{selectedInfo?.engravingText || '(none)'}
											</p>
										</If>
									</div>
									<div className={clsx("tw-flex tw-justify-center tw-items-center tw-flex-col tw-w-24", {'tw-relative': selectedTab === item.eventKey})}>
										{selectedInfo?.url && !(/^None$/i).test(selectedText) &&
										<img className="tw-rounded-full tw-h-7 tw-w-7 tw-mb-1" alt="img"
											src={`${getUrl(selectedInfo?.url)}`}/>}
										{selectedText && <p className="tw-text-center tw-text-xs tw-text-primary-color tw-">{selectedText}</p>}
										<If condition={item.eventKey === 'summary'}>
											<p className="tw-text-center tw-text-primary-color tw-text-lg">
												{price}
											</p>
										</If>
									</div>
								</div>
								<If condition={item.eventKey === 'summary' && styles.showShippingEstimation}>
                  <div className='tw-text-center tw-mt-2'>
                      <div>Ships as soon as:</div>
                      <div className='tw-font-bold'>{getLastestShippingDate(shippingOptions)}</div>
                  </div>
                </If>
								<If condition={selectedTab === item.eventKey}>
									<div id="active-h-border" style={horizontalStyle}></div>
									<div id="active-v-border" style={verticalStyle}></div>
								</If>
							</div>
						)
					})}
				</div>
				<div className="tw-flex tw-flex-col tw-gap-y-4 tw-w-fulltw-py-3 tw-pr-3">
					<If condition={['customize'].includes(subpath)}>
						<button className="tw-flex tw-items-center tw-cursor-pointer tw-bg-transparent" onClick={handleDone}>
							<img src={leftArrow} alt="right_arrow" />
							<span className='tw-pl-1.5 tw-underline tw-text-sm tw-text-primary-color'>Done Customizing</span>
						</button>
						<div className="tw-flex tw-items-center tw-w-full">
							<button className='!tw-bg-brand-color !tw-text-white tw-w-full tw-px-9 tw-py-2 tw-rounded-full tw-font-semibold sm:tw-text-sm lg:tw-text-base' onClick={handleAddToCart}>{`${product?.price} - Add to Cart`}</button>
						</div>
					</If>
					<If condition={styles.enableDefaultDone}>
						<button className='!tw-bg-brand-color !tw-text-white tw-w-3/4 tw-px-9 tw-py-2 tw-rounded-tertiary-border-radius tw-font-medium tw-mx-auto' onClick={handleDone}>
							{['johnAtencio', 'johnAtencio-stage'].includes(subpath) ? 'DONE' : 'Done'}
						</button>
					</If>
					<If condition={['tqDiamonds', 'tqDiamonds-stage'].includes(subpath)}>
						<button className='!tw-bg-brand-color !tw-text-white tw-w-3/4 tw-px-9 tw-py-2 tw-rounded-tertiary-border-radius tw-font-medium tw-mx-auto' onClick={handleAddToCart}>
							Add to Cart
						</button>
					</If>
				</div>
			</div>
		</div>
	)
}

export const handleLogoClick = () => {
	if(!window.origin.includes('ringdesignersstudio.com') && !window.origin.includes('localhost')) {
		window.location.href = '/';
	}
}

export default DesktopSidebar;
